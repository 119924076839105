<template>
  <div class="wysiwyg">
    <ol class="list-decimal ml-5">
      <li>
        「繪製展牆」：點選「繪製展牆」，可於方格展間畫面中，按滑鼠左鍵自由拖曳展牆大小及位移。
      </li>
      <li>
        「編輯物件」：於欲編輯的展品上，點選「編輯物件」，可進入物件畫面進行編輯。
      </li>
      <li>
        「刪除物件」：於欲刪除的展品上，點選「刪除物件」，該物件即於方格展間畫面中刪除。
      </li>
      <li>「載入模板」：點選「載入模板」可直接套好系統預設之展牆配置。</li>
    </ol>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
